// #region RESET
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  outline: none;
  font: inherit;
  vertical-align: baseline; 
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; 
}

body {
  line-height: 1; 
}

ol, ul {
  list-style: none; 
}

blockquote, q {
  quotes: none; 
}

blockquote {
  &:before, &:after {
    content: '';
    content: none; 
  } 
}

q {
  &:before, &:after {
    content: '';
    content: none; 
  } 
}

table {
  border-collapse: collapse;
  border-spacing: 0; 
}
// #endRegion
@import '../../../../config/theme';

html{
  font-size: 62.5% !important;
  touch-action: manipulation;
}

body{
  font-size: $font-base;
  background: $color-dark;
  font-family: $font-body;
}

.wrapper{
  display: flex;
  flex-direction: column;
  transition: filter .4s ease;
  position: fixed;
  width: 100%;
  height: 100%;
  background: $color-dark;

  &.blur{
    filter: blur(.5rem);
  }

  &__content{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}



h1, h2, h3, h4, h5, h6{
  font-family: $font-header;
}

strong{
  font-weight: $weight-semi !important;
}

h1{
  font-size: $h1;
}
h2{
  font-size: $h2;
}
h3{
  font-size: $h3;
}
h4{
  font-size: $h4;
}
h5{
  font-size: $h5;
}
h6{
  font-size: $h6;
}