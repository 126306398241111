@import "../../config/theme";

.modal-overlay{
  z-index: $z-modal;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ReactModalPortal{
  overscroll-behavior-y: contain;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  overflow: hidden;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
  
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.modal {
  overflow: hidden;
  position: absolute;
  z-index: $z-modal+1;
  padding: 0;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: $el-rad/3;
  border: none;
  width: calc(100% - 4rem);
  max-width: 50rem;
  height: calc(100% - 9rem);
  max-height: 20rem;
  background: $color-light;
  height: auto;

  .button--button-close-card{
    position: absolute;
    top: -#{$el-height};
    left: $el-pad/2;
    color: $color-light;
    text-align: center;
    padding-left: $el-pad/2;
    padding-right: $el-pad/2;
    
    i{
      margin-right: 0;
      font-size: 2.4rem;
    }
  }

  &--el{
    width: calc(100% - 6rem);
    max-width: 50rem;
    height: auto;
    max-height: 50rem;

    .modal__body{
      height: calc(100% - 4.5rem);
    }
  }

  &--transparent{
    background: transparent !important;
  }

  &--banner {
    max-height: 45rem;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    
    .modal__header{
      height: auto;
      justify-content: center;

      img {
        max-width: 100%;
      }
    }

    .modal__body{
      padding: 2rem 2rem 0 2rem;
      min-height: 10rem;

      h3{
        font-weight: $weight-bold;
        text-align: center;
        margin-bottom: $el-mar;
      }

      p{
        color: #afaeae;
        line-height: 1.4;
        font-size: 1.4rem;
      }
      
      &__action{
        padding: 2rem;
      }
    }
  }

  &--card{
    width: 100%;
    bottom: 0;
    height: calc(100% - #{$header-height});
    max-height: none;
    max-width: none;
    transform: translate(-50%, 0);
    overflow: visible;
    border-radius: 0;

    &-smaller{
      max-width: 50rem;
      max-height: 50rem;
    }
  }

  &__drag-header{
    background: $color-light;
    height: $header-height;
    display: flex;
    align-items: center;

    &__close{
      width: 4rem;
      height: 4rem;
      background: $color-light;
      border-radius: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 4rem;
      margin-left: $el-mar/2;
      margin-right: $el-mar/2;
      position: absolute;

      .button{
        padding: 0;
        height: 4rem;
        width: 4rem;
      }
    }

    &__content{
      flex: 1 1 auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__draggable-icon{
        display: block;
        width: 5rem;
        height: .4rem;
        border-radius: 4rem;
        background: $color-light;
        margin-bottom: $el-mar/2;
      }
    }
  }

  &__header {
    background: $color-light;
    height: $modal-height;
    display: flex;
    align-items: center;

    &__text {
      font-weight: $weight-bold;
      align-items: center;
      height: $el-pad;
      padding: $el-pad;
      font-size: $font-base;
      width: calc(100% - 6.5rem);
      line-height: 1.2;
    }

    &__icon {
      display: inline-block;

      i {
        vertical-align: middle;
        color: $color-grey;
      }
    }

    &__close{
      padding: $el-pad;
      margin-left: auto;

      svg{
        width: 1.5rem;
        height: auto;

        *{
          fill: $color-secondary;
        }
      }
    }
  }

  &__body {
    display: block;
    height: calc(100% - 12rem);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &__content{
      padding: $el-pad;
      line-height: 1.4;
      color: rgba($color-dark, .6);
      font-weight: $weight-semi;

      a{
        color: $color-primary;
      }
    }
  }

  &__action {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: $el-pad;

    .button{
      font-size: 1.4rem;
      font-weight: $weight-bold;
      border-radius: 5rem;
      margin-right: $el-mar/2;

      &:last-child{
        margin-right: 0;
      }
    }
  }
}