//#region ------ FONT
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500&display=swap&subset=japanese');
$font-base: 1.4rem;
$weight-normal: 400;
$weight-semi: 500;
$weight-bold: 700;
$font-header: 'HiraMinProN-W6', 'Noto Sans JP', sans-serif;
$font-body: 'Noto Sans JP', sans-serif;
$font-accent: 'Didot', 'Noto Sans JP', sans-serif;
//#endregion

//#region ------ COLOR
$color-primary: #F3B15D;
$color-primary-light: #E7DCCB;
$color-secondary: #D7B690;
$color-light: #FFFFFF;
$color-dark: #111013;
$color-grey: #211F22;
$color-grey-dark: #2c2c31;
$color-grey-light: #403F45;
$color-text: #EBEBEB;
$color-description: #9d9c9c;
$color-danger: #cc0000;
//#endregion

//#region ------ HEADING
$h1: $font-base * 3.2;
$h2: $font-base * 2.2;
$h3: $font-base * 1.4;
$h4: $font-base * 1.2;
$h5: $font-base;
$h6: $font-base * 0.9; 
//#endregion

//#region ------ BOX MODEL
$el-pad: 1.5rem;
$el-mar: 1.5rem;
$el-height: 5rem;
$header-height: 6rem;
$modal-height: $el-height;
//#endregion

//#region ------ BORDER
$el-rad: 1.5rem;
$button-rad: .5rem;
//#endregion

//#region ------ LAYOUT
//#endregion

//#region ------ ELEMENT LAYER
$z-modal: 103;
//#endregion
