@import '../../../../config/theme';

.home{
  height: 100%;
  background-position: center top;
  background-repeat: repeat-x;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: $color-dark;

  &__title{
    flex: 0 0 6rem;
    display: flex;
    align-items: center;
    padding: 1.5rem 2rem;

    h3{
      font-size: 1.8rem;
      color: $color-light;
    }

    @media (max-height: 568px){
      flex-basis: 4rem;
    }
  }

  &__content{
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &__button {
      margin: 2.0rem auto 0;
    }
    
  }

  .congestion-box{
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .map-slider{
    margin-top: 3.5rem;
  }

  .dining-slider{
    margin-top: 3.5rem;
  }

  .service-box{
    margin-top: 3.5rem;
  }
}

.qrreader-button-language{
  padding-top: 2.0rem;
  padding-bottom: 2.0rem;
  position: absolute;
  right: 2.0rem;
  bottom: 2.0rem;
  height: 4.0rem;
  z-index: 999;
}