@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500&display=swap&subset=japanese);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500&display=swap&subset=japanese);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500&display=swap&subset=japanese);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500&display=swap&subset=japanese);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500&display=swap&subset=japanese);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500&display=swap&subset=japanese);
.qrreader {
  background-position: center top;
  background-repeat: repeat-x;
  position: fixed;
  z-index: 103;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: #111013; }

.wrap {
  display: flex;
  flex-flow: column;
  width: 100%; }

.qrreader-title {
  z-index: 104;
  width: 100%;
  color: #E7DCCB;
  text-align: center;
  padding-top: 9.5rem; }
  .qrreader-title h3 {
    font-size: 2.4rem;
    margin-bottom: .75rem;
    font-family: "Didot", "Noto Sans JP", sans-serif; }

.qrreader-inner {
  z-index: 104;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }

.qrreader-wrap {
  width: 100%; }
  .qrreader-wrap__guide {
    z-index: 105;
    position: absolute;
    top: 19rem;
    left: 0;
    bottom: 6%;
    height: calc(100vw*(900/1000));
    width: 100%; }
    .qrreader-wrap__guide__frame {
      width: 100%; }
    .qrreader-wrap__guide svg {
      z-index: 106;
      width: 100%;
      height: calc(100vw*(900/1000));
      object-fit: cover; }
    .qrreader-wrap__guide video {
      z-index: -100;
      width: 100%;
      height: calc(100vw*(900/1000));
      top: 0;
      left: 0;
      position: absolute;
      object-fit: cover; }
  .qrreader-wrap__title {
    z-index: 106;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    white-space: pre-wrap;
    text-align: center;
    margin-top: 70%;
    width: 80%;
    height: 100%;
    color: white; }
  .qrreader-wrap__button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 3.0rem;
    z-index: 106;
    margin: 0 auto; }

.spinner {
  width: 70px;
  position: absolute;
  text-align: center; }
  .spinner > div {
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s; }
  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s; }
  .spinner--secondary > div {
    background-color: #D7B690; }
  .spinner--light > div {
    background-color: #FFFFFF; }

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.button {
  background: transparent;
  background-color: #D7B690;
  color: #FFFFFF;
  border-radius: 0.75rem;
  font-weight: 500;
  height: 5rem;
  box-shadow: none;
  border: none;
  box-sizing: border-box;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  -webkit-transition: opacity .2s ease, background-color .2s ease;
  transition: opacity .2s ease, background-color .2s ease;
  outline: none;
  line-height: 1;
  cursor: pointer;
  padding-left: 1.5rem;
  padding-right: 1.5rem; }
  .button__content {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    margin: 0 auto; }
    .button__content svg {
      width: 1.5rem;
      height: auto; }
  .button--disabled {
    opacity: .3; }
  .button:disabled {
    opacity: .6; }
  .button--full {
    width: 100%; }
  .button--primary {
    background: #F3B15D;
    color: #FFFFFF; }
    .button--primary-transparent {
      background: transparent;
      color: #F3B15D; }
    .button--primary-outline {
      background: transparent;
      border: 2px solid #F3B15D;
      color: #F3B15D; }
    .button--primary:active {
      background: #ef9a2e; }
  .button--secondary {
    background: #D7B690;
    color: #FFFFFF; }
    .button--secondary-transparent {
      background: transparent;
      color: #D7B690; }
    .button--secondary-outline {
      background: transparent;
      border: 2px solid #D7B690;
      color: #D7B690; }
    .button--secondary:active {
      background: #c99d6b; }
  .button--danger {
    background: #cc0000;
    color: #FFFFFF; }
    .button--danger-transparent {
      background: transparent;
      color: #cc0000; }
    .button--danger-outline {
      background: transparent;
      border: 2px solid #cc0000;
      color: #FFFFFF; }
    .button--danger:active {
      background: #990000; }
  .button--default {
    background: #403F45;
    color: #FFFFFF; }

.modal-overlay {
  z-index: 103;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.ReactModalPortal {
  overscroll-behavior-y: contain;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
  overflow: hidden; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal {
  overflow: hidden;
  position: absolute;
  z-index: 104;
  padding: 0;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  border: none;
  width: calc(100% - 4rem);
  max-width: 50rem;
  height: calc(100% - 9rem);
  max-height: 20rem;
  background: #FFFFFF;
  height: auto; }
  .modal .button--button-close-card {
    position: absolute;
    top: -5rem;
    left: 0.75rem;
    color: #FFFFFF;
    text-align: center;
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
    .modal .button--button-close-card i {
      margin-right: 0;
      font-size: 2.4rem; }
  .modal--el {
    width: calc(100% - 6rem);
    max-width: 50rem;
    height: auto;
    max-height: 50rem; }
    .modal--el .modal__body {
      height: calc(100% - 4.5rem); }
  .modal--transparent {
    background: transparent !important; }
  .modal--banner {
    max-height: 45rem;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
    .modal--banner .modal__header {
      height: auto;
      justify-content: center; }
      .modal--banner .modal__header img {
        max-width: 100%; }
    .modal--banner .modal__body {
      padding: 2rem 2rem 0 2rem;
      min-height: 10rem; }
      .modal--banner .modal__body h3 {
        font-weight: 700;
        text-align: center;
        margin-bottom: 1.5rem; }
      .modal--banner .modal__body p {
        color: #afaeae;
        line-height: 1.4;
        font-size: 1.4rem; }
      .modal--banner .modal__body__action {
        padding: 2rem; }
  .modal--card {
    width: 100%;
    bottom: 0;
    height: calc(100% - 6rem);
    max-height: none;
    max-width: none;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    overflow: visible;
    border-radius: 0; }
    .modal--card-smaller {
      max-width: 50rem;
      max-height: 50rem; }
  .modal__drag-header {
    background: #FFFFFF;
    height: 6rem;
    display: flex;
    align-items: center; }
    .modal__drag-header__close {
      width: 4rem;
      height: 4rem;
      background: #FFFFFF;
      border-radius: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 4rem;
      margin-left: 0.75rem;
      margin-right: 0.75rem;
      position: absolute; }
      .modal__drag-header__close .button {
        padding: 0;
        height: 4rem;
        width: 4rem; }
    .modal__drag-header__content {
      flex: 1 1 auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .modal__drag-header__content__draggable-icon {
        display: block;
        width: 5rem;
        height: .4rem;
        border-radius: 4rem;
        background: #FFFFFF;
        margin-bottom: 0.75rem; }
  .modal__header {
    background: #FFFFFF;
    height: 5rem;
    display: flex;
    align-items: center; }
    .modal__header__text {
      font-weight: 700;
      align-items: center;
      height: 1.5rem;
      padding: 1.5rem;
      font-size: 1.4rem;
      width: calc(100% - 6.5rem);
      line-height: 1.2; }
    .modal__header__icon {
      display: inline-block; }
      .modal__header__icon i {
        vertical-align: middle;
        color: #211F22; }
    .modal__header__close {
      padding: 1.5rem;
      margin-left: auto; }
      .modal__header__close svg {
        width: 1.5rem;
        height: auto; }
        .modal__header__close svg * {
          fill: #D7B690; }
  .modal__body {
    display: block;
    height: calc(100% - 12rem);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
    .modal__body__content {
      padding: 1.5rem;
      line-height: 1.4;
      color: rgba(17, 16, 19, 0.6);
      font-weight: 500; }
      .modal__body__content a {
        color: #F3B15D; }
  .modal__action {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 1.5rem; }
    .modal__action .button {
      font-size: 1.4rem;
      font-weight: 700;
      border-radius: 5rem;
      margin-right: 0.75rem; }
      .modal__action .button:last-child {
        margin-right: 0; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  outline: none;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  font-size: 62.5% !important;
  touch-action: manipulation; }

body {
  font-size: 1.4rem;
  background: #111013;
  font-family: "Noto Sans JP", sans-serif; }

.wrapper {
  display: flex;
  flex-direction: column;
  -webkit-transition: -webkit-filter .4s ease;
  transition: -webkit-filter .4s ease;
  transition: filter .4s ease;
  transition: filter .4s ease, -webkit-filter .4s ease;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #111013; }
  .wrapper.blur {
    -webkit-filter: blur(0.5rem);
            filter: blur(0.5rem); }
  .wrapper__content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }

h1, h2, h3, h4, h5, h6 {
  font-family: "HiraMinProN-W6", "Noto Sans JP", sans-serif; }

strong {
  font-weight: 500 !important; }

h1 {
  font-size: 4.48rem; }

h2 {
  font-size: 3.08rem; }

h3 {
  font-size: 1.96rem; }

h4 {
  font-size: 1.68rem; }

h5 {
  font-size: 1.4rem; }

h6 {
  font-size: 1.26rem; }

.home {
  height: 100%;
  background-position: center top;
  background-repeat: repeat-x;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #111013; }
  .home__title {
    flex: 0 0 6rem;
    display: flex;
    align-items: center;
    padding: 1.5rem 2rem; }
    .home__title h3 {
      font-size: 1.8rem;
      color: #FFFFFF; }
    @media (max-height: 568px) {
      .home__title {
        flex-basis: 4rem; } }
  .home__content {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
    .home__content__button {
      margin: 2.0rem auto 0; }
  .home .congestion-box {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .home .map-slider {
    margin-top: 3.5rem; }
  .home .dining-slider {
    margin-top: 3.5rem; }
  .home .service-box {
    margin-top: 3.5rem; }

.qrreader-button-language {
  padding-top: 2.0rem;
  padding-bottom: 2.0rem;
  position: absolute;
  right: 2.0rem;
  bottom: 2.0rem;
  height: 4.0rem;
  z-index: 999; }

